import React from 'react'
import styled from 'styled-components'

import StepIcon from './step-icon'

const StepContainer = styled.div`
  margin: 1rem auto;
`

const TextConainer = styled.div`
  text-align: center;
`

export default ({ children, icon }) => {
  return (
    <StepContainer>
      {icon && <StepIcon icon={icon} />}
      <TextConainer>{children}</TextConainer>
    </StepContainer>
  )
}