import React from "react"
import { graphql } from 'gatsby'
import { Row, Col } from 'reactstrap'
import Helmet from 'react-helmet'

import Layout from '../layout'

import BackgroundContainer from '../components/image/background-container'
import PageContainer from '../components/common/page-container'
import TitleContainer from '../components/common/title-container'
import Title from '../components/common/title'
import Subtitle from '../components/common/subtitle'

import Step from '../components/home/step'
import StepLink from '../components/home/step-link'

export default (props) => (
  <Layout title="Home">
    <Helmet>
      <meta name="description" content="packagedby is a full service software development agency, providing full-stack software development for online businesses. We are a team of experienced technology professionals dedicated to finding the right solution to your businesses needs."/>
    </Helmet>

    <BackgroundContainer header={props.data.header}>
      <TitleContainer>
        <Title>packagedby</Title>
        <Subtitle>Your technology partners</Subtitle>
      </TitleContainer>
    </BackgroundContainer>

    <PageContainer>
      <Row>
        <Col sm={12} md="">        
          <StepLink href="/services/technical-consultancy">
            <Step icon={['fal', 'project-diagram']}>
              Technical Consultancy
            </Step>
          </StepLink>
        </Col>
        <Col sm={12} md="">
          <StepLink href="/services/api-development">
            <Step icon={['fal', 'cloud']}>
              API Development
            </Step>
          </StepLink>
        </Col>
        <Col sm={12} md="">
          <StepLink href="/services/integration">
            <Step icon={['fal', 'plug']}>
              API Integration
            </Step>
          </StepLink>
        </Col>
        <Col sm={12} md="">
          <StepLink href="/services/software-as-a-service">
            <Step icon={['fal', 'layer-group']}>
              Software as a Service
            </Step>
          </StepLink>
        </Col>
        <Col sm={12} md="">
          <StepLink href="/services/front-end-development">
            <Step icon={['fal', 'browser']}>
              UI Development
            </Step>
          </StepLink>
        </Col>
      </Row>
    </PageContainer>
  </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`