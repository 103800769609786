import React from 'react'
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const FontAwesomeContainer = styled.span`
  font-size: 8rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 1rem;
`

const FontAwesomeBackground = styled(FontAwesomeIcon)`
  color: #2c3237;
`

const Icon = (props) => <FontAwesomeContainer className="fa-layers fa-fw">
  <FontAwesomeBackground icon={['fas', 'circle']} />
  <FontAwesomeIcon {...props} inverse transform="shrink-7" />
</FontAwesomeContainer>


export default Icon
