import Link from '../common/link'
import styled from 'styled-components'

const StepLink = styled(Link)`
  color: #212529;
  font-size: 1.2rem;
  font-family: Ubuntu;

  &:hover {
    color: #212529;
    text-decoration: none;
  }
`

export default StepLink